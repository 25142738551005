<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation title="Account Activation" :hideLogin="true" />

    <div class="mainBox mainBox55 border shadow-sm mb-0">
        <div v-if="!isActivated" class="row">

            <div class="col-12 col-lg-4 pb-4 text-center">
                <table border="0" align="center" height="100%">
                    <tr>
                        <td><fa icon="shield" class="align-middle" style="font-size: 120px" /></td>
                    </tr>
                </table>
            </div>

            <div class="col-12 col-lg-8 pb-4">
                <div class="row">
                    <div class="col-12">
                        <div class="mb-3">Welcome to SignOn - a simple, fast and secure way to sign documents online.</div>
                        <div class="mb-3">Kindly set your new password to activate your account for more features.</div>
                    </div>
                    <div class="col-12 mt-3 mb-2">
                        <input type="password" v-model="password1" @input="valPassword" placeholder="Password" class="form-control" />
                    </div>

                    <div class="col-12 mb-3">
                        <div v-if="!pwd_length"><fa icon="circle-exclamation" class="text-danger pe-2" />At least 11 characters</div>
                        <div v-if="!pwd_number"><fa icon="circle-exclamation" class="text-danger pe-2" />Minimum 1 numeric digit</div>
                        <div v-if="!pwd_lowercase"><fa icon="circle-exclamation" class="text-danger pe-2" />Minimum 1 lowercase letter</div>
                        <div v-if="!pwd_uppercase"><fa icon="circle-exclamation" class="text-danger pe-2" />Minimum 1 capital letter</div>
                        <div v-if="!pwd_special"><fa icon="circle-exclamation" class="text-danger pe-2" />Minimum 1 special character</div>
                    </div>

                    <div v-if="(password2 && password2.length > 0) || passwordFormat " class="col-12 pb-3">
                        <input type="password" v-model="password2" @input="valPasswordMatch" placeholder="Reconfirm password" class="form-control"/>
                        <div v-if="password2 && password2.length > 0 && !passwordMatch" class="mt-1">
                            <fa icon="circle-exclamation" class="text-danger pe-2" />Password not match
                        </div>
                    </div>

                    <div class="col-12 col-lg-8 pb-3">
                        You will be redirected to a upload page after set password
                    </div>

                    <div class="col-12 col-lg-4 pb-0 text-end">
                        <button class="btn btn-info text-white" :disabled="passwordFormat && passwordMatch ? false : true" @click="setPassword">Set Password</button>
                    </div>
                </div>
            </div>

        </div>
    </div>    
</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { ref, computed, inject, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import funcs from '@/functions/function'
import { useStore } from '@/stores/store'

export default {
    name: 'Activate',
    components: { TopNavigation, Alert },
    setup () {

        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs

        const store = useStore()
        const { getDisplayName, updDisplayName } = useStore()

        const isActivated = ref(false) // Indicate if the activationCode activated successfully. Success will proceed to reset password.
        const password1 = ref(null)
        const password2 = ref(null)

        const displayName = ref('')

        const passwordFormat = ref(false)
        const passwordMatch = ref(false)

        const pwd_length = ref(false)
        const pwd_number = ref(false)
        const pwd_lowercase = ref(false)
        const pwd_uppercase = ref(false)
        const pwd_special = ref(false)

        const isLogin = ref(false)

        const closeAlert = (index) => {
            alert.value.splice(index, 1)

        }

        const valPassword = () => {
            pwd_length.value = password1.value.length >= 11
            pwd_number.value = /\d/.test(password1.value)
            pwd_lowercase.value = /[a-z]/.test(password1.value)
            pwd_uppercase.value = /[A-Z]/.test(password1.value)
            pwd_special.value = /[!@#\$%\^\&*\)\(+=._-]/.test(password1.value)

            if (pwd_length.value && pwd_number.value && pwd_lowercase.value && pwd_uppercase.value && pwd_special.value) {
                passwordFormat.value = true
                valPasswordMatch()

            } else {
                passwordFormat.value = false

            }

        }

        const valPasswordMatch = () => {
            if (password1.value === password2.value) {
                passwordMatch.value = true

            } else {
                passwordMatch.value = false

            }

        }

        const setPassword = () => {
            if (password1.value !== password2.value) {
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: 'Password and reconfirm password do not match. '
                })

            } else {

                // Proceed tor set password (axios)
                const p = {
                    password: password1.value
                }
                
                let url = '/auth/activate/' + route.params.activationCode
                axios.put( url, p )
                    .then((res) => {

                        if (res.data.status === 1) {
                            // Activate successfully
                            localStorage.setItem('userId', res.data.data.userId)
                            localStorage.setItem('sessionId', res.data.data.sessionId)
                            localStorage.setItem('userStatus', 'ACTIVE') 

                            displayName.value = computed({
                                get: () => store.getDisplayName.value,
                                set: store.updDisplayName(res.data.data.displayName),
                            })
                            displayName.value = store.getDisplayName.value 

                            setTimeout(() => {

                                let redirectPath = '/uploadDoc'
                                if (route.query.redirect) {
                                    
                                    // route.query.fr = res.data.data.displayName
                                    if (route.query.redirect === 'InfoProj') {
                                        redirectPath = '/p/' + route.query.p
                                    } else if (route.query.redirect === 'ViewProjDoc') {
                                        redirectPath = '/p/' + route.query.p + '/' + route.query.d
                                    } else if (route.query.redirect === 'ViewDocInfo') {
                                        redirectPath = '/d/' + route.query.d

                                    } else if (route.query.redirect === 'UploadDocNew') {
                                        redirectPath = '/uploadDoc'
                                    } else if (route.query.redirect === 'UploadDoc') {
                                        redirectPath = '/uploadDoc/' + route.query.p
                                    } else if (route.query.redirect === 'ProjectOverview') {
                                        redirectPath = '/project/' + route.query.p
                                    } else if (route.query.redirect === 'ProjectList') {
                                        redirectPath = '/project'
                                    } else if (route.query.redirect === 'VerifyDoc') {
                                        redirectPath = '/doc/verify'
                                    } else if (route.query.redirect === 'PdfView') {
                                        redirectPath = '/pdf/view/' + route.query.p + '/' + route.query.d + '/upload'
                                    } else if (route.query.redirect === 'Signatory') {
                                        redirectPath = '/signatory'
                                    } else if (route.query.redirect === 'PdfDetail') {
                                        redirectPath = '/pdf/detail'
                                    } else if (route.query.redirect === 'PdfDetailSignee') {
                                        redirectPath = '/pdf/detailSignee'
                                    } else if (route.query.redirect === 'PdfDetailSignee2') {
                                        redirectPath = '/pdf/detailSignee2'
                                    } else if (route.query.redirect === 'AddSignee') {
                                        redirectPath = '/addSignee'
                                    } else if (route.query.redirect === 'ReviewInvite') {
                                        redirectPath = '/reviewInvite'
                                    } else if (route.query.redirect === 'ManageAccount') {
                                        redirectPath = '/auth/manageaccount'
                                    } else {
                                        redirectPath = '/dashboard'
                                    }

                                    // console.info('---- login s3 - has redirect > redirectPath', redirectPath)
                                }

                                router.push(redirectPath)
                                .then(() => {
                                    // refresh the page so that can capture sessionId in store
                                    router.go() 
                                })
                            , 5000})

                            alert.value.push({
                                class: "success",
                                title: "SUCCESS",
                                message: "Password is updated. You will be redirected to login page shortly."
                            })

                        } else {
                            func.addLog('activate', 'setPassword', res)

                            if (res && res.data !== null && res.data !== undefined) {
                                if (res.data.message === 'user_was_activated') {
                                    if(isLogin.value === true) {
                                        alert.value.push({
                                            class: "success",
                                            title: "SUCCESS",
                                            message: "You account is activated. You will be redirected to upload page shortly."
                                        })
                                    } else {
                                        alert.value.push({
                                            class: "success",
                                            title: "SUCCESS",
                                            message: "You account is activated. You will be redirected to login page shortly."
                                        })
                                    }

                                    setTimeout(() => {
                                        router.push('/uploadDoc')
                                    }, 500)

                                } else if (res.data.message === 'refId_expired') {
                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: 'Your activation link is expired. (' + res.data.status + ')'
                                    })

                                } else if (res.data.message === 'invalid_action' || res.data.message === 'hacking_attempt' || res.data.message === 'refId_not_found'){
                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: 'Your activation link is not working. Please report the error (error code) to our support if you continue to face the same problem. (' + res.data.status + ')'
                                    })

                                } else {
                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: func.getError(res.data)
                                    })

                                }

                            }

                        }

                    })
                    .catch((error) => {
                        func.addLog('activate', 'setPassword - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                    })

            }

        }

        const chkIsLogin = async () => {

            try {
                const res = await axios.get('/auth/session')

                if( res.data.status === 1) {
                    isLogin.value = true

                } else {
                    isLogin.value = false
                }

            } catch (error) {
                isLogin.value = false

            }
        }

        onMounted(() => {
            if (route.params.activationCode === null || route.params.activationCode === undefined) {
                // Redirect to page not found if no activationCode was detected
                router.push('/pageNotFound')
                
            } 

            chkIsLogin()

        })

        return { 
            alert, closeAlert, isActivated, 
            password1, password2, setPassword, valPassword, valPasswordMatch, 
            pwd_length, pwd_number, pwd_lowercase, pwd_uppercase, pwd_special, 
            passwordFormat, passwordMatch, isLogin
        }
    }
}
</script>

<style>

</style>